import { useRoute } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import Toast from 'react-native-toast-notifications';

import { WebModal } from '@components/Modals/WebModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { RestyleProps } from '@components/shared/Icon/RestyleIcon';
import { MemberRoleShowSkills } from '@components/User/MemberRoleShowSkills.web';
import {
  ListProjectsDocument,
  ProjectMemberRole,
  TeamMemberRole,
  User,
  useCreateInviteMutation,
  useRemoveProjectMembersMutation,
  useUpdateProjectMembersMutation,
} from '@graphql/generated';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import { useProjectMembership } from '@hooks/useProjectMembership';
import useSearch from '@hooks/useSearch';
import { AddProjectMembersStack } from '@navigation/addMembers/add-project-members-stack';
import { ProjectsStackScreenProps } from '@navigation/projects/projects-stack';
import theme from '@themes/theme';
import {
  getProjectRoleEnumValue,
  selectableProjectRoles,
} from '@utils/projects';

interface CustomButtonProps {
  backgroundColor: RestyleProps['backgroundColor'];
  prefix?: React.ReactChild;
  onPress: () => void;
  disabled?: boolean;
  label: string;
  variant: 'copy' | 'create';

  accessibilityLabel: string;
}

export const ProjectMembers: React.FC = () => {
  const { t } = useTranslation('models');
  const [isSortName, setSortName] = useState(false);
  const [isSortNameAsc, setSortNameAsc] = useState(false);
  const {
    params: { projectId },
  } = useRoute<ProjectsStackScreenProps<'project-members'>['route']>();
  const { search } = useSearch();
  const { project, loading } = useProjectFromQuery({
    projectId,
  });
  const projectMembers = project?.members || [];
  const { isEditorOrHigher } = useProjectMembership(project);
  const isViewer = !isEditorOrHigher;

  const alphabetizedMembers = sortBy(projectMembers, [
    (item) => get(item, 'user.firstName')?.toLowerCase(),
    (item) => get(item, 'user.lastName')?.toLowerCase(),
  ]);

  const alphabetizedMembers1 = isSortNameAsc
    ? alphabetizedMembers
    : alphabetizedMembers.reverse();

  const sortedMembers = isSortName
    ? [...alphabetizedMembers1]
    : [...projectMembers]?.sort((a) =>
        a.role === ProjectMemberRole.Owner ? -1 : 1
      );

  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const toastRef = useRef(null);

  const copyToClipboard = async (link: string) => {
    if (!link) return; // Early return if link is falsy
    await Clipboard.setStringAsync(link);

    toastRef.current?.show('Copied', {
      duration: 2000,
    });
  };

  const [createInviteMutation] = useCreateInviteMutation({
    onCompleted: ({ createInvite }) => {
      if (createInvite) {
        const formattedLink = createInvite.replace(
          'register/register-with-email',
          'invited-user'
        );
        copyToClipboard(formattedLink);
      }
    },
  });

  const [updateProjectMembers] = useUpdateProjectMembersMutation({
    refetchQueries: [{ query: ListProjectsDocument }],
  });

  const [removeProjectMember] = useRemoveProjectMembersMutation({
    refetchQueries: [{ query: ListProjectsDocument }],
  });

  const removeMemberFromProject = (memberId: string) => {
    if (project) {
      const { id, color, name, teamId, members } = project;
      // TODO: this query should be scoped to only require necessary data to update project members

      const users = projectMembers.filter((item) => item.user.id !== memberId);

      removeProjectMember({
        variables: {
          id,
          attributes: {
            color,
            name,
            teamId,
            members: users.map((u) => {
              return {
                userId: u.user.id,
                role: u.role,
              };
            }),
          },
        },
        optimisticResponse: {
          updateProject: {
            ...project,
            members,
            usersCount: users.length,
          },
        },
      });
    }
  };

  const updateProjectMember = (userId: User['id'], role: ProjectMemberRole) => {
    if (project) {
      const { id } = project;

      updateProjectMembers({
        variables: {
          id,
          attributes: [
            {
              userId,
              role: getProjectRoleEnumValue(role),
            },
          ],
        },
      });
    }
  };

  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  if (!loading && !project) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Project not found</Text>
      </Box>
    );
  }

  const getHeader = () => {
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        paddingHorizontal='m'
        paddingVertical='xs'
        borderRadius='xs'
        backgroundColor='grey01'
        marginHorizontal='l'>
        <Box flex={1} flexDirection='row' alignItems='center'>
          <Text variant='labelRequired' color='grey04'>
            {t('projects.members.name')}
          </Text>
          <Icon
            marginLeft='xs'
            variant='xxs'
            name='ArrowUpNarrow'
            color={isSortName && isSortNameAsc ? 'greenSecondary' : 'grey02'}
            style={{ marginRight: -3 }}
            onPress={() => {
              if (isSortName && isSortNameAsc) {
                setSortName(false);
                setSortNameAsc(false);
              } else {
                setSortName(true);
                setSortNameAsc(true);
              }
            }}
          />
          <Icon
            variant='xxs'
            name='ArrowDownNarrow'
            color={isSortName && !isSortNameAsc ? 'greenSecondary' : 'grey02'}
            style={{ marginLeft: -3 }}
            onPress={() => {
              if (isSortName && !isSortNameAsc) {
                setSortName(false);
                setSortNameAsc(false);
              } else {
                setSortName(true);
                setSortNameAsc(false);
              }
            }}
          />
        </Box>
        <Box flex={1} flexDirection='row' justifyContent='flex-start'>
          <Text variant='labelRequired' color='grey04'>
            {t('projects.members.skills')}
          </Text>
        </Box>
        <Box flex={1} flexDirection='row' justifyContent='flex-start'>
          <Text variant='labelRequired' color='grey04'>
            {t('projects.members.email')}
          </Text>
        </Box>
        <Box flex={0.5} flexDirection='row' justifyContent='flex-end'>
          <Text variant='labelRequired' color='grey04'>
            {t('projects.members.phone')}
          </Text>
        </Box>
        <Box flex={0.5} flexDirection='row' justifyContent='flex-end'>
          <Text variant='labelRequired' color='grey04'>
            {t('projects.members.status')}
          </Text>
        </Box>
      </Box>
    );
  };

  const CustomButton: React.FC<CustomButtonProps> = ({
    backgroundColor,
    prefix,
    onPress,
    label,
    disabled,
    variant,
    accessibilityLabel,
  }) => {
    return (
      <Button
        disabled={disabled}
        backgroundColor={backgroundColor}
        prefix={prefix}
        variant={variant}
        onPress={onPress}
        isSmall
        height={32}
        accessibilityLabel={accessibilityLabel}>
        {label}
      </Button>
    );
  };

  const handleCreateInvite = () => {
    createInviteMutation({
      variables: { projectId },
    });
  };

  const openAddMembersModal = () => setShowAddMembersModal(true);

  return (
    <>
      <Box flex={1}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-end'
          marginHorizontal='l'
          marginTop='m'
          marginBottom='xs'>
          <Box width={160} marginRight='s'>
            <CustomButton
              backgroundColor='white'
              variant='copy'
              prefix={<Icon variant='s' name='Link' color='black' />}
              onPress={handleCreateInvite}
              label={t('shared:copyLinkToInvite')}
              accessibilityLabel={t('shared:copyLinkToInvite')}
            />
          </Box>

          {!isViewer && (
            <Box width={160}>
              <CustomButton
                disabled={project?.archivedAt}
                backgroundColor='black'
                variant='create'
                prefix={
                  <Icon
                    variant='s'
                    name='UserPlus'
                    color={project?.archivedAt ? 'grey04' : 'white'}
                  />
                }
                onPress={openAddMembersModal}
                label={t('shared:addMembers')}
                accessibilityLabel={t('shared:addMembers')}
              />
            </Box>
          )}
        </Box>
        {getHeader()}
        <Box flex={1} alignContent='center' justifyContent='center'>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={sortedMembers}
            style={{ paddingHorizontal: theme.spacing.l }}
            renderItem={({ item }) => (
              <Box marginVertical='xxxs' paddingHorizontal='m' key={item.id}>
                <MemberRoleShowSkills<ProjectMemberRole>
                  roles={selectableProjectRoles}
                  member={item}
                  updateRole={
                    (project?.currentUserRole === ProjectMemberRole.Admin ||
                      project?.currentUserRole === ProjectMemberRole.Owner ||
                      project?.currentUserTeamRole === TeamMemberRole.Owner ||
                      project?.currentUserTeamRole === TeamMemberRole.Admin) &&
                    item.role !== ProjectMemberRole.Owner
                      ? (i) => updateProjectMember(item.user.id, i)
                      : undefined
                  }
                  removeMember={(member) =>
                    removeMemberFromProject(member.user.id)
                  }
                  filterVal={search}
                  avaterSize='small'
                  showUserInfo
                />
              </Box>
            )}
            keyExtractor={(item) => item.user.id.toString()}
            ItemSeparatorComponent={() => (
              <Box height={1} backgroundColor='grey02' marginVertical='xs' />
            )}
            ListHeaderComponent={() => <Box marginTop='xs' />}
            ListFooterComponent={() => <Box marginBottom='listFooter' />}
          />
        </Box>
        <Toast ref={toastRef} />
      </Box>

      <WebModal
        onClose={() => setShowAddMembersModal(false)}
        visible={showAddMembersModal}
        width={504}
        height={512}>
        <AddProjectMembersStack
          teamId={project?.teamId}
          onComplete={() => setShowAddMembersModal(false)}
          onClose={() => setShowAddMembersModal(false)}
        />
      </WebModal>
    </>
  );
};
function createInviteMutation(arg0: { variables: { projectId: string } }) {
  throw new Error('Function not implemented.');
}
