import {
  createRestyleComponent,
  createVariant,
  color,
  border,
  spacing,
  backgroundColor,
  ColorProps,
  BorderProps,
  SpacingProps,
  BackgroundColorProps,
  VariantProps,
} from '@shopify/restyle';
import React from 'react';
import { TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Box, Text } from '@components/Restyle';
import theme, { Theme } from '@themes/theme';

type RestyleProps = ColorProps<Theme> &
  BorderProps<Theme> &
  SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  VariantProps<Theme, 'buttonVariants'>;

const RestyleButton = createRestyleComponent<RestyleProps, Theme>([
  color,
  border,
  spacing,
  backgroundColor,
  createVariant({ themeKey: 'buttonVariants' }),
]);

type Props = RestyleProps & {
  fullWidth?: boolean;
  children: React.ReactChild;
  prefix?: React.ReactChild;
  suffix?: React.ReactChild;
  disabled?: boolean;
  alignTextLeft?: boolean;
  onPress?: () => void;
  prefixMarginRight?: keyof typeof theme.spacing;
  activeOpacity?: number;
  isSmall?: boolean;
  float?: 'bottom' | 'bottom-right' | 'bottom-inset' | 'bottom-right-inset';
  accessibilityLabel?: string;
  height?: number;
};

const Button: React.FC<Props> = ({
  fullWidth = false,
  disabled = false,
  children,
  onPress,
  prefix,
  suffix,
  alignTextLeft,
  prefixMarginRight,
  activeOpacity = 0.2,
  isSmall,
  float,
  accessibilityLabel,
  height,
  ...rest
}: Props) => {
  const insets = useSafeAreaInsets();
  let styleOverrides = fullWidth ? {} : { alignItems: 'center' };
  let color = null;
  let labelVariant = null;

  switch (rest.variant) {
    case 'primary':
      color = 'white';
      labelVariant = 'buttonLabel';
      break;

    case 'webprimary':
      color = 'white';
      labelVariant = 'webBody';
      break;

    case 'text':
      color = 'black';
      break;

    case 'textweb':
      color = 'black';
      labelVariant = 'webBody';
      break;

    case 'textSecondary':
      color = 'greenSecondary';
      break;

    case 'create':
      color = 'white';
      labelVariant = 'taskBtnLabel';
      break;

    case 'copy':
      color = 'black';
      labelVariant = 'taskBtnLabel';
      break;

    case 'edit':
      color = 'textPrimary';
      labelVariant = 'labelSmall';
      break;

    case 'editBold':
      color = 'textPrimary';
      labelVariant = 'buttonLabel';
      break;
    case 'editSelected':
      color = 'textPrimary';
      labelVariant = 'labelSmall';
      break;
    case 'reversed':
      color = 'black';
      labelVariant = 'buttonLabel';
      break;

    case 'icon':
      color = 'white';
      labelVariant = null;
      styleOverrides = { ...styleOverrides, padding: 'xs' };
      buttonPadding = 'xs';
      break;

    case 'delete':
      color = 'alertRed';
      labelVariant = 'buttonLabel';
      break;

    case 'logout':
      color = 'alertRed';
      labelVariant = 'webSmall';
      break;

    default:
      color = 'black';
      labelVariant = 'bodySecondary';
  }

  const renderButton = () => {
    const height1 = height ? height : 48;
    const txtColor = Platform.OS === 'web' ? 'grey04' : 'textSecondary';
    return (
      <TouchableOpacity
        style={{ width: fullWidth ? '100%' : undefined }}
        disabled={disabled}
        activeOpacity={activeOpacity}
        onPress={onPress}
        accessibilityLabel={accessibilityLabel}>
        <RestyleButton
          {...rest}
          style={[
            styleOverrides,
            disabled && {
              backgroundColor: theme.colors.grey02,
              borderColor: theme.colors.grey02,
            },
            {
              justifyContent: 'center',
              height: isSmall ? height1 : 56,
            },
          ]}>
          {prefix && (
            <Box
              width={16}
              height={16}
              marginRight={prefixMarginRight || 's'}
              alignItems='center'
              justifyContent='center'
              pointerEvents='none'>
              {prefix}
            </Box>
          )}
          <Box
            alignItems={alignTextLeft ? 'flex-start' : 'center'}
            justifyContent='center'
            style={(!prefix || alignTextLeft) && { flex: 1 }}>
            <Text
              variant={labelVariant}
              color={!disabled ? color : txtColor}
              style={[
                alignTextLeft && {
                  marginLeft: theme.spacing.m,
                },
              ]}>
              {children}
            </Text>
          </Box>
          {suffix && (
            <Box width='m' height='m' marginLeft='xs' pointerEvents='none'>
              {suffix}
            </Box>
          )}
        </RestyleButton>
      </TouchableOpacity>
    );
  };

  const floatBottomRight = () => {
    return (
      <Box
        style={{
          position: 'absolute',
          bottom: theme.spacing.m,
          right: theme.spacing.m,
        }}>
        {renderButton()}
      </Box>
    );
  };

  const floatBottomInset = () => {
    return (
      <Box
        style={[
          styles.submitButton,
          { bottom: insets.bottom ? theme.spacing.s : 0 },
        ]}
        marginBottom='m'
        marginHorizontal='m'>
        {renderButton()}
      </Box>
    );
  };

  const floatBottomRightInset = () => {
    return (
      <Box
        style={{
          position: 'absolute',
          bottom: insets.bottom ? theme.spacing.xl : theme.spacing.l,
          right: theme.spacing.m,
        }}>
        {renderButton()}
      </Box>
    );
  };

  if (float === 'bottom-right') return floatBottomRight();
  if (float === 'bottom-inset') return floatBottomInset();
  if (float === 'bottom-right-inset') return floatBottomRightInset();

  return renderButton();
};

const styles = StyleSheet.create({
  submitButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default Button;
