import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { TaskListTable, TaskSection } from '@components/TaskList/TaskListTable';
import { User, Task, TaskSortOption, TaskGroup } from '@graphql/generated';
import { useListTasksFromQuery } from '@hooks/useListTasksFromQuery';
import { ContactProfileProps } from '@navigation/contacts/contact-tabs.web';

const PERPAGECOUNT = 50;

export const ContactTasks: React.FC<ContactProfileProps> = ({ userId }) => {
  const navigation = useNavigation();
  const { groupedCurrentTasks } = useGetCurrentTasks(userId);

  const { groupedPastTasks } = useGetPastTasks(userId);

  return (
    <TaskListTable
      key={userId}
      onPress={(task: Task) => {
        navigation.navigate('contacts', {
          screen: 'contact-task-details',
          userId,
          taskId: task.id,
        });
      }}
      tasks={[groupedCurrentTasks, groupedPastTasks]}
    />
  );
};

const useGetCurrentTasks = (userId: User['id']) => {
  const { tasks, loading, fetchMore, pageInfo } = useListTasksFromQuery({
    userId,
    first: PERPAGECOUNT,
    sortBy: TaskSortOption.NameAsc,
    skip: !userId,
    dueGroup: TaskGroup.Current,
  });
  const fetchFromCursor = () => {
    if (!tasks || !pageInfo?.hasNextPage || loading) return;

    const cursor = pageInfo.endCursor;

    fetchMore({
      variables: {
        userId,
        after: cursor,
        first: PERPAGECOUNT,
        sortBy: TaskSortOption.NameAsc,
      },
    });
  };

  const groupedCurrentTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: TaskGroup.Current,
        name: TaskGroup.Current,
        count: tasks.length,
      },
      data: tasks,
    },
    loadMore: pageInfo?.hasNextPage ? fetchFromCursor : undefined,
  };

  return { groupedCurrentTasks };
};

const useGetPastTasks = (userId: User['id']) => {
  const { tasks, loading, fetchMore, pageInfo } = useListTasksFromQuery({
    userId,
    first: PERPAGECOUNT,
    sortBy: TaskSortOption.NameAsc,
    skip: !userId,
    dueGroup: TaskGroup.Overdue,
  });
  const fetchFromCursor = () => {
    if (!tasks || !pageInfo?.hasNextPage || loading) return;

    const cursor = pageInfo.endCursor;

    fetchMore({
      variables: {
        userId,
        after: cursor,
        first: PERPAGECOUNT,
        sortBy: TaskSortOption.NameAsc,
      },
    });
  };

  const groupedPastTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: TaskGroup.Overdue,
        name: TaskGroup.Overdue,
        count: tasks.length,
      },
      data: tasks,
    },
    loadMore: pageInfo?.hasNextPage ? fetchFromCursor : undefined,
  };

  return { groupedPastTasks };
};
