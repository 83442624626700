import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView } from 'react-native';

import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { Box, ShadowBox } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { TasksTabList } from '@components/TaskList/TasksTabList';
import {
  Project,
  TaskGroup,
  TaskSortOption,
  Team,
  User,
} from '@graphql/generated';
import {
  FilterableTaskSortOptions,
  SectionFilter,
  SortFilterName,
  TaskSectionOptions,
  useTasksFilter,
} from '@hooks/useTasksFilter';
import { AppStackScreenProps } from '@navigation/app-stack';
import { TasksFilterChips } from '@screens/Tasks/TasksFilterChips';
import theme from '@themes/theme';
const Tab = createMaterialTopTabNavigator();

export const TasksTabbar: React.FC = () => {
  const { t } = useTranslation();
  const navigation =
    useNavigation<AppStackScreenProps<'tasks-stack'>['navigation']>();

  const {
    sortByFilter,
    setSortByFilter,
    projectFilters,
    setProjectFilters,
    userFilters,
    teamFilters,
    sectionOption,
    projectId,
    userNotViewer,
    userTeamMember,
    setUserFilters,
    setTeamFilters,
    setSectionOption,
  } = useTasksFilter();

  useEffect(() => {
    projectId &&
      setProjectFilters([
        {
          name: '',
          value: projectId,
          type: 'TasksFilterProject',
        },
      ]);
  }, []);

  const resetFilters = () => {
    setSortByFilter(undefined);
    setProjectFilters([]);
    setUserFilters([]);
    setTeamFilters([]);
    setSectionOption(TaskSectionOptions.Active);
  };

  useEffect(() => {
    resetFilters();
  }, [
    setSortByFilter,
    setProjectFilters,
    setUserFilters,
    setTeamFilters,
    setSectionOption,
  ]);
  const projectIds =
    projectFilters.length > 0
      ? projectFilters.map((p) => p.value)
      : [projectId];
  const teamIds = teamFilters.map((t) => t.value);
  const memberIds = userFilters.map((u) => u.value);

  const inverseSortByOption = (): [
    sortOption: FilterableTaskSortOptions,
    sortName: SortFilterName
  ] => {
    switch (sortByFilter?.value) {
      case TaskSortOption.NameAsc:
        return [TaskSortOption.NameDesc, SortFilterName.NameDesc];
      case TaskSortOption.NameDesc:
        return [TaskSortOption.NameAsc, SortFilterName.NameDesc];
      case TaskSortOption.DueDateAsc:
        return [TaskSortOption.DueDateDesc, SortFilterName.DueDateDesc];

      case TaskSortOption.DueDateDesc:
        return [TaskSortOption.DueDateAsc, SortFilterName.DueDateAsc];

      // INVERSE OF DEFAULT SORT OPTION WHEN LOADING TASKS TAB
      default:
        return [TaskSortOption.DueDateDesc, SortFilterName.DueDateDesc];
    }
  };

  const sectionOptionChip: SectionFilter = {
    type: 'TasksFilterSectionOption',
    name: sectionOption,
    value: sectionOption,
  };

  const isCompletedTaskSelected =
    sectionOptionChip.value === TaskSectionOptions.Completed;
  let hide =
    (sectionOption === TaskSectionOptions.Active ||
      sectionOption === TaskSectionOptions.Completed) &&
    sortByFilter === undefined &&
    projectFilters.length === 0 &&
    teamFilters.length === 0 &&
    userFilters.length === 0;

  useEffect(() => {
    hide =
      (sectionOption === TaskSectionOptions.Active ||
        sectionOption === TaskSectionOptions.Completed) &&
      sortByFilter === undefined &&
      projectFilters.length === 0 &&
      teamFilters.length === 0 &&
      userFilters.length === 0;
  }, [sectionOption, sortByFilter, projectFilters, teamFilters, userFilters]);

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <ShadowBox variant='thin'>
        <Box flexDirection='row' alignItems='center' paddingHorizontal='m'>
          <CustomTabBar {...props} spacing={theme.spacing.l} />
          <Icon
            variant='xs'
            name='ArrowUpNarrow'
            color={
              sortByFilter &&
              (sortByFilter.value == TaskSortOption.NameAsc ||
                sortByFilter.value == TaskSortOption.DueDateAsc)
                ? 'greenSecondary'
                : 'black'
            }
            style={{ marginRight: -3 }}
            onPress={() => {
              const newSortByOption = inverseSortByOption();
              setSortByFilter({
                name: newSortByOption[1],
                value: newSortByOption[0],
                type: 'TasksFilterSortOption',
              });
            }}
          />
          <Icon
            variant='xs'
            name='ArrowDownNarrow'
            color={
              sortByFilter &&
              (sortByFilter.value == TaskSortOption.NameDesc ||
                sortByFilter.value == TaskSortOption.DueDateDesc)
                ? 'greenSecondary'
                : 'black'
            }
            style={{ marginLeft: -3 }}
            marginRight='m'
            onPress={() => {
              const newSortByOption = inverseSortByOption();
              setSortByFilter({
                name: newSortByOption[1],
                value: newSortByOption[0],
                type: 'TasksFilterSortOption',
              });
            }}
          />
          <Icon
            variant='l'
            name='BarChart2'
            color='black'
            onPress={() =>
              navigation.navigate('tasks-filter-modal', { projectId })
            }
          />
        </Box>

        <Box
          pt='xs'
          py={hide ? 'none' : 'm'}
          flexDirection='row'
          marginHorizontal={hide ? 'none' : 'xxs'}>
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-end' }}>
            <Box flexDirection='row-reverse'>
              <TasksFilterChips
                filters={[
                  ...(sectionOptionChip.value !== TaskSectionOptions.All
                    ? [sectionOptionChip]
                    : []),
                  ...teamFilters,
                  ...projectFilters,
                  ...userFilters,
                ]}
                projectId={projectId}
                onSortDismiss={() => setSortByFilter(undefined)}
                onProjectDismiss={(value: Project['id']) => {
                  setProjectFilters(
                    projectFilters.filter((p) => p.value !== value)
                  );
                }}
                onUserDismiss={(value: User['id']) => {
                  setUserFilters(userFilters.filter((u) => u.value !== value));
                }}
                onTeamDismiss={(value: Team['id']) => {
                  setTeamFilters(teamFilters.filter((t) => t.value !== value));
                }}
                onSectionOptionDismiss={() => {
                  setSectionOption(TaskSectionOptions.Active);
                }}
              />
            </Box>
          </ScrollView>
        </Box>
      </ShadowBox>
    );
  };

  return (
    <Box flexDirection='row' flex={1}>
      {!isCompletedTaskSelected ? (
        <>
          <Tab.Navigator
            screenOptions={{ swipeEnabled: Platform.OS !== 'web', lazy: true }}
            tabBar={renderTabBar}
            initialRouteName='tasks-current'
            backBehavior='none'>
            <Tab.Screen
              name='tasks-current'
              options={{
                tabBarLabel: 'Current',
                tabBarAccessibilityLabel: `Today's tasks`,
              }}
              listeners={{
                tabPress: () => {
                  setSortByFilter(undefined);
                },
              }}>
              {() => {
                return (
                  <TasksTabList
                    sortBy={sortByFilter?.value}
                    group={TaskGroup.Current}
                    projectIds={projectIds}
                    teamIds={teamIds}
                    memberIds={memberIds}
                    sectionOption={sectionOption}
                  />
                );
              }}
            </Tab.Screen>
            <Tab.Screen
              name='tasks-overdue'
              options={{
                tabBarLabel: 'Overdue',
                tabBarAccessibilityLabel: 'Overdue tasks',
              }}>
              {() => (
                <TasksTabList
                  sortBy={
                    sortByFilter?.value
                      ? sortByFilter?.value
                      : TaskSortOption.DueDateAsc
                  }
                  group={TaskGroup.Overdue}
                  projectIds={projectIds}
                  teamIds={teamIds}
                  memberIds={memberIds}
                  sectionOption={sectionOption}
                />
              )}
            </Tab.Screen>
          </Tab.Navigator>
        </>
      ) : (
        <Tab.Navigator
          screenOptions={{ swipeEnabled: Platform.OS !== 'web', lazy: true }}
          tabBar={renderTabBar}
          initialRouteName='tasks-completed'
          backBehavior='none'>
          <Tab.Screen
            name='tasks-completed'
            options={{
              tabBarLabel: 'Completed',
              tabBarAccessibilityLabel: `Completed's tasks`,
            }}>
            {() => {
              return (
                <TasksTabList
                  sortBy={sortByFilter?.value}
                  projectIds={projectIds}
                  teamIds={teamIds}
                  memberIds={memberIds}
                  sectionOption={sectionOption}
                />
              );
            }}
          </Tab.Screen>
        </Tab.Navigator>
      )}
      <Button
        disabled={!!projectId && userTeamMember && !userNotViewer}
        prefix={
          <Icon
            variant='l'
            name='Plus'
            color={
              !!projectId && userTeamMember && !userNotViewer
                ? 'black'
                : 'white'
            }
          />
        }
        onPress={() => navigation.navigate('task-create-modal', { projectId })}
        borderRadius='xl'
        variant='primary'
        prefixMarginRight='s'
        float='bottom-right'
        accessibilityLabel='New Task'>
        {t('models:tasks.buttons.newTask')}
      </Button>
    </Box>
  );
};
