import { useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';

import { DateRange } from '@components/DateRangePicker/DateRangePickerModal';
import { ContactType } from '@components/Invite/Contact';
import { Box } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { TaskListNewTable } from '@components/TaskList/TaskListNewTable';
import { TaskSection } from '@components/TaskList/TaskListTable';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { TasksFilterBar } from '@components/Web/TasksFilterBar';
import {
  Project,
  Scalars,
  SearchableResult,
  TaskGroup,
  TaskPriority,
  TaskSortOption,
  TaskStatus,
  useSearchLazyQuery,
} from '@graphql/generated';
import { useListTasksFromQuery } from '@hooks/useListTasksFromQuery';
import { TasksTabStackScreenProps } from '@navigation/tasks/tasks-tab-stack';
import { Search } from '@root/types';
import { beginningOfDayUTC } from '@utils/formatters/date';
import { filterByDateRange } from '@utils/tasks';
import { isTaskSearchResult } from '@utils/typeGuards';

const DEBOUNCE = 500;
const PER_PAGE_COUNT = 50;

type TaskSearchParam = {
  skip: boolean;
  projectIds?: string[];
  priority?: TaskPriority[];
  dueDateBegin?: Scalars['ISO8601DateTime'];
  dueDateEnd?: Scalars['ISO8601DateTime'];
  memberIds?: string[];
};
export const Tasks: React.FC = () => {
  const { params: { taskId } = {} } =
    useRoute<TasksTabStackScreenProps<'tasks'>['route']>();

  const [search] = useState('');
  const [_refreshing, setRefreshing] = useState(false);
  const [isMaximize] = useState(false);

  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [selectedAssignees, setSelectedAssignees] = useState<ContactType[]>([]);
  const [selectedDueDateRange, setSelectedDueDateRange] = useState<DateRange>({
    start: undefined,
    end: undefined,
  });
  const [selectedPriorities, setSelectedPriorities] = useState<TaskPriority[]>(
    []
  );

  const param: TaskSearchParam = {
    skip: !!search,
    projectIds:
      selectedProjects.length > 0
        ? selectedProjects.map((p) => p.id)
        : undefined,
    priority:
      selectedPriorities.length > 0 ? [...selectedPriorities] : undefined,
    dueDateBegin: selectedDueDateRange.start
      ? beginningOfDayUTC(
          dayjs(selectedDueDateRange.start).toDate()
        ).toISOString()
      : undefined,
    dueDateEnd: selectedDueDateRange.end
      ? beginningOfDayUTC(
          dayjs(selectedDueDateRange.end).toDate()
        ).toISOString()
      : undefined,
    memberIds:
      selectedAssignees.length > 0
        ? selectedAssignees.map((t) => t.id)
        : undefined,
  };

  const { groupedOverDueTasks } = useGetOverDueTask(param);

  const { groupedCurrentTasks } = useGetCurrentTask(param);

  const { groupedCompletedTasks } = useGetCompletedTask(param);
  // #region Global Search
  const [searchLoading, setSearchLoading] = useState(false);
  const [globalSearch, { data }] = useSearchLazyQuery();

  const { setIsTaskWebPanelOpen, setIdTaskDetailOpen, setMainPanelWidth } =
    useWebDrawer();

  const { tasks } = useListTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: TaskSortOption.DueDateDesc,
    onCompleted: () => setRefreshing(false),
    onError: () => setRefreshing(false),
    skip: !!search,
  });

  const taskResults =
    (data &&
      (data.search as Search<SearchableResult>[])
        .filter(isTaskSearchResult)
        .map((r) => r.record)) ||
    [];

  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: search,
        size: 20,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: false,
        includeTasks: true,
      },
      onCompleted: () => setSearchLoading(false),
      onError: () => setSearchLoading(false),
    });

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [search]
  );

  useEffect(() => {
    if (search) {
      setSearchLoading(true);
      debouncedGlobalSearch();
    }
    return () => debouncedGlobalSearch.cancel();
  }, [search]);
  // #endregion

  // #region Filter
  const [dateRange] = useState<DateRange>({});
  const filteredTasks = filterByDateRange(
    search ? taskResults : tasks,
    ({ dueDate }) => dueDate,
    dateRange
  );
  // #endregion

  return (
    <Box
      flex={1}
      onLayout={(e) => setMainPanelWidth(e.nativeEvent.layout.width)}>
      <Box flex={1} flexDirection='row' justifyContent='space-between'>
        {!isMaximize && (
          <Box flex={1} mt='xs'>
            <TasksFilterBar
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              selectedAssignees={selectedAssignees}
              setSelectedAssignees={setSelectedAssignees}
              selectedPriorities={selectedPriorities}
              setSelectedPriorities={setSelectedPriorities}
              selectedDueDateRange={selectedDueDateRange}
              setSelectedDueDateRange={setSelectedDueDateRange}
            />
            <Box mt='xs'></Box>
            {searchLoading && (
              <Box mt='xxs'>
                <ActivityIndicatorLoading />
              </Box>
            )}
            {!searchLoading && (
              <TaskListNewTable
                key={taskId}
                tasks={
                  search
                    ? filteredTasks
                    : [
                        groupedCurrentTasks,
                        groupedOverDueTasks,
                        groupedCompletedTasks,
                      ]
                }
                onPress={(item, _) => {
                  setIdTaskDetailOpen(item.id);
                  setIsTaskWebPanelOpen(true);
                }}
                isProjectShown={true}
                isHeaderTabsShow={true}
                isArrowVisible={true}
                isFromMyTask={true}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useGetCurrentTask = (param: TaskSearchParam) => {
  const { tasks, fetchFromCursor, pageInfo } = useListTasksFromQuery({
    first: PER_PAGE_COUNT,
    ...param,
    dueGroup: TaskGroup.Current,
    excludeCompleted: true,
  });

  const groupedCurrentTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: TaskGroup.Current,
        name: TaskGroup.Current,
        count: tasks.length,
      },
      data: tasks,
      hasNextPage: pageInfo?.hasNextPage,
    },
    loadMore: fetchFromCursor,
  };

  return { groupedCurrentTasks };
};
const useGetOverDueTask = (param: TaskSearchParam) => {
  const { tasks, fetchFromCursor, pageInfo } = useListTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: TaskSortOption.DueDateAsc,
    ...param,
    dueGroup: TaskGroup.Overdue,
    excludeCompleted: true,
  });

  const groupedOverDueTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: TaskGroup.Overdue,
        name: TaskGroup.Overdue,
        count: tasks.length,
      },
      data: tasks,
      hasNextPage: pageInfo?.hasNextPage,
    },
    loadMore: fetchFromCursor,
  };

  return { groupedOverDueTasks };
};

const useGetCompletedTask = (param: TaskSearchParam) => {
  const { tasks, fetchFromCursor, pageInfo } = useListTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: TaskSortOption.DueDateDesc,
    ...param,
    collaboratorsOnly: true,
    status: TaskStatus.Completed,
  });

  const groupedCompletedTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: 'COMPLETED',
        name: 'COMPLETED',
        count: tasks.length,
      },
      data: tasks,
      hasNextPage: pageInfo?.hasNextPage,
    },
    loadMore: fetchFromCursor,
  };

  return { groupedCompletedTasks };
};
