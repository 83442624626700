import { Header } from '@react-navigation/elements';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { ProjectDetailModal } from '@components/Modals/ProjectDetailModal.web';
import { WebModal } from '@components/Modals/WebModal.web';
import { RecentSearches } from '@components/RecentSearches/RecentSearches.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import SearchInput from '@components/shared/SearchInput/SearchInput.web';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { DrawerHeaderProps } from '@components/Web/Navigator/WebDrawerNavigator';
import useGlobalSearch from '@hooks/useGlobalSearch';
import { useOutsideClick } from '@hooks/useOutsideClick.web';
import { useProjectMembership } from '@hooks/useProjectMembership';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import GlobalSearch from '@screens/Global/GlobalSearch.web';
import theme from '@themes/theme';
import { useLocation } from '@utils/locationUtils';

export const DrawerHeader = ({
  layout,
  navigation,
  options,
  route,
}: DrawerHeaderProps) => {
  const { headerTitle, selectedProject, setSelectedProject } =
    useWebDrawerNavigator();

  const { isMemberOrOwner } = useProjectMembership(selectedProject);

  const isTeamOwnerOrAdmin =
    selectedProject?.currentUserTeamRole === 'OWNER' ||
    selectedProject?.currentUserTeamRole === 'ADMIN';

  const nonMember = !(isMemberOrOwner || isTeamOwnerOrAdmin);

  const { t } = useTranslation();
  const { openAddress } = useLocation();
  const {
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
  } = useWebDrawer();

  const {
    isSearchFocus,
    setIsSearchFocus,
    search,
    setSearch,
    storeRecentSearchTerm,
    setSearchWidth,
  } = useGlobalSearch();

  const refGlobalSearchBox = useRef<HTMLDivElement>();
  const ref1 = useRef<HTMLDivElement>();
  const ref2 = useRef<HTMLDivElement>();
  const isEventPointInModal = (event: MouseEvent) => {
    const rect = refGlobalSearchBox?.current?.getBoundingClientRect();
    const rect2 = ref1?.current?.getBoundingClientRect();
    const rect3 = ref2.current?.getBoundingClientRect();
    return (
      (rect &&
        event.x > rect.x &&
        event.x < rect.x + rect.width &&
        event.y > rect.y &&
        event.y < rect.y + rect.height) ||
      (rect2 &&
        event.x > rect2.x &&
        event.x < (rect?.x ?? 0) + (rect?.width ?? 0) &&
        event.y > rect2.y &&
        event.y < (rect3 ? rect3.y + (rect3?.height ?? 0) : 570))
    );
  };
  const handleOutsideClick = useCallback((event: MouseEvent) => {
    const rect = refGlobalSearchBox?.current?.getBoundingClientRect();
    if ((rect?.x ?? 0) > 0 && !isEventPointInModal(event)) {
      setIsSearchFocus(false);
      setShowSearchBox(false);
      storeRecentSearchTerm();
    }
  }, []);

  const ref = useOutsideClick<HTMLDivElement>(handleOutsideClick);

  const maxTitleWidth = 340;
  const minMainPanelWidth = 440;
  const [mainPanelWidth, setMainPanelWidth] =
    useState<number>(minMainPanelWidth);
  //rightPartWidth -- notification Icon and new task button
  const [rightPartWidth, setRightPartWidth] = useState<number>(188);
  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [isHoveredSearch, setHoveredSearch] = useState<boolean>(false);

  const onCloseSearch = () => {
    setSearch('');
    setIsSearchFocus(false);
    storeRecentSearchTerm();
    setShowSearchBox(false);
  };

  document.onkeydown = function (evt) {
    if (evt.key == 'Escape') {
      // Escape key pressed
      onCloseSearch();
    }
  };

  return (
    <>
      <Box
        style={{ minWidth: minMainPanelWidth }}
        onLayout={(e) => {
          setMainPanelWidth(e.nativeEvent.layout.width);
        }}>
        <Header
          {...options}
          headerStyle={{
            height: 73,
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.grey02,
          }}
          layout={layout}
          title={
            headerTitle ?? options.title ?? options.drawerLabel ?? route.name
          }
          headerTitleContainerStyle={{ right: 0 }}
          headerTitle={({ children }) => (
            <Box>
              <Box height={17} />
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-start'
                pl='xs'
                height={32}
                style={{
                  width: mainPanelWidth - rightPartWidth - theme.spacing.xs,
                }}>
                <>
                  {headerTitle && (
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedProject(undefined);
                        navigation.navigate('allprojects');
                      }}>
                      <Icon
                        color='grey04'
                        variant='s'
                        marginHorizontal='xs'
                        name='ChevronLeft'
                      />
                    </TouchableOpacity>
                  )}

                  <Text
                    accessibilityLabel='Project title'
                    variant='heading2'
                    numberOfLines={1}
                    style={{ maxWidth: maxTitleWidth }}>
                    {children}
                  </Text>

                  {headerTitle && !nonMember && (
                    <>
                      {selectedProject && (
                        <Box marginLeft='xs'>
                          <ProjectDetailModal
                            accessibilityLabel='Options'
                            project={selectedProject}
                            popPosition='bottom'
                            popSize='small'
                          />
                        </Box>
                      )}
                    </>
                  )}
                </>
                <Box flex={1} />
                <Box
                  mr='xxxs'
                  onLayout={(e) => setSearchWidth(e.nativeEvent.layout.width)}>
                  <Box
                    alignItems='center'
                    justifyContent='center'
                    style={{ height: 32, width: 32 }}
                    borderRadius='xs'
                    onMouseEnter={() => setHoveredSearch(true)}
                    onMouseLeave={() => setHoveredSearch(false)}
                    paddingHorizontal='xs'
                    paddingVertical='xs'
                    backgroundColor={isHoveredSearch ? 'grey01' : undefined}
                    mr='xs'>
                    <TouchableOpacity
                      accessibilityLabel='Global Search'
                      onPress={() => {
                        setShowSearchBox(true);
                      }}>
                      <Icon color='textPrimary' variant='l' name='Search' />
                    </TouchableOpacity>
                  </Box>
                </Box>
              </Box>
              {!showSearchBox && headerTitle && selectedProject?.address && (
                <TouchableOpacity
                  onPress={() =>
                    selectedProject.address &&
                    openAddress(
                      selectedProject.address,
                      selectedProject.latitude,
                      selectedProject.longitude
                    )
                  }>
                  <Text ml='xl' pl='xxs' variant='metadata' color='grey05'>
                    {selectedProject.address}
                  </Text>
                </TouchableOpacity>
              )}
              {!(!showSearchBox && headerTitle && selectedProject?.address) && (
                <Box height={17} />
              )}
            </Box>
          )}
          headerRight={() => (
            <Box
              justifyContent='flex-end'
              mt='s'
              mb='s'
              onLayout={(e) => {
                setRightPartWidth(e.nativeEvent.layout.width);
              }}>
              <Box flex={1} />
              <Box flexDirection='row'>
                <Box style={{ width: 0, height: 0 }} ref={ref} />
                <Box justifyContent='center' flexDirection='row'>
                  <TouchableOpacity
                    disabled={selectedProject?.archivedAt}
                    onPress={() => {
                      setIdTaskDetailOpen('');
                      setIsTaskWebPanelOpen(true);
                      if (
                        (selectedProject &&
                          route.name === 'projects-stack' &&
                          (selectedProject?.name ?? '')) ===
                        (headerTitle ??
                          options.title ??
                          options.drawerLabel ??
                          route.name)
                      ) {
                        setIdProjectOfTaskDetailOpen(selectedProject.id ?? '');
                      } else {
                        setIdProjectOfTaskDetailOpen('');
                      }
                    }}
                    accessibilityLabel={t('models:tasks.buttons.newTask')}>
                    <Box
                      mr='l'
                      width={122}
                      height={32}
                      flexDirection='row'
                      borderWidth={1}
                      backgroundColor={
                        selectedProject?.archivedAt ? 'grey02' : 'white'
                      }
                      borderColor={
                        selectedProject?.archivedAt ? 'grey02' : 'black'
                      }
                      borderRadius='m'
                      alignItems='center'
                      px='s'
                      ml='xs'>
                      <Box
                        width={16}
                        height={16}
                        marginRight='xxs'
                        alignItems='center'
                        justifyContent='center'
                        pointerEvents='none'>
                        <Icon
                          variant='m'
                          name='Plus'
                          color={
                            selectedProject?.archivedAt ? 'grey06' : 'black'
                          }
                        />
                      </Box>
                      <Box
                        alignItems='center'
                        justifyContent='center'
                        style={{ flex: 1 }}>
                        <Text
                          variant='labelSmall'
                          color={
                            selectedProject?.archivedAt ? 'grey06' : 'black'
                          }>
                          {t('models:tasks.buttons.newTask')}
                        </Text>
                      </Box>
                    </Box>
                  </TouchableOpacity>
                </Box>
              </Box>
            </Box>
          )}
        />
        <Box borderBottomWidth={1} borderBottomColor='grey02'></Box>
      </Box>
      <WebModal
        visible={showSearchBox}
        width={820}
        height={700}
        title='Global Search'
        titleVariant='labelEmphasized'
        titleColor='textPrimary'
        marginHorizontal='l'
        onClose={() => onCloseSearch()}>
        <Box flex={1} mt='xs' pt='l' pb='m'>
          <Box mx='l' accessibilityLabel='Search input'>
            <SearchInput
              autoFocus={showSearchBox}
              value={search}
              onTextChanged={(value: string) => {
                setSearch(value);
              }}
              onFocus={() => {
                !isSearchFocus && setIsSearchFocus(true);
              }}
              onCancel={() => {
                setSearch('');
                setIsSearchFocus(false);
                storeRecentSearchTerm();
              }}
              onEscPress={() => onCloseSearch()}
              placeHolder='Find Tasks, Projects or Files'
              height={32}
            />
          </Box>
          <Box flex={1} mt='l'>
            {search.length === 0 && <RecentSearches boxRef={ref2} />}
            <GlobalSearch
              visible={search.length > 0}
              doAfterPressItem={() => {
                setShowSearchBox(false);
              }}
            />
          </Box>
        </Box>
      </WebModal>
    </>
  );
};
